.airdrop_home_card {
  box-shadow: 0px 16px 50px 0px rgba(0, 0, 0, 0.1);
  border: none !important;
  border-radius: 18px !important;
  padding-top: 38px;
  padding-left: 40px;
  padding-right: 35px;
  padding-bottom: 38px;
}
.offer_created_text {
  color: var(--offer-created-text-color);
  font-family: var(--primary-poppins-font);
  font-size: 0.9em;
  font-style: normal;
  font-weight: 600;
}
.offer_created_date {
  color: var(--offer-created-date);
  font-family: var(--primary-poppins-font);
  font-size: 0.9em;
  font-style: normal;
  font-weight: 400;
}
.offer_created_remaining_days {
  color: var(--airdrop-card-remaining-color);
  font-family: var(--primary-poppins-font);
  font-size: 0.9em;
  font-style: normal;
  font-weight: 400;
}
.claim_now_btn {
  background-color: var(--primary-blue-color);
  display: flex;
  font-family: var(--primary-poppins-font);
  width: 90%;
  padding-top: 15px;
  padding-bottom: 15px;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 30px;
  color: var(--white);
  font-size: 1em;
  position: relative;
}
.claim_now_btn_disabled {
  background-color: var(--primary-blue-color);
  display: flex;
  font-family: var(--primary-poppins-font);
  width: 90%;
  padding-top: 15px;
  padding-bottom: 15px;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 30px;
  color: var(--white);
  font-size: 1em;
  position: relative;
  opacity: 0.8;
}
.claim_now_btn:hover {
  background: linear-gradient(to right, #0d7dd2 25%, #406aa8 75%);
}
.airdrops_home_card_text_h {
  color: var(--offer-created-text-color);
  font-family: var(--primary-poppins-font);
  font-size: 1.5em;
  font-style: normal;
  font-weight: 600;
}
.airdrops_home_card_text_p {
  color: var(--offer-created-date);
  font-family: var(--primary-poppins-font);
  font-size: 0.95em;
  font-style: normal;
  font-weight: 400;
  padding-right: 20px;
}
.see_more_text_btn {
  color: var(--primary-blue-color);
  font-family: var(--primary-poppins-font);
  font-size: 0.95em;
  font-style: normal;
  font-weight: 400;
  cursor: pointer;
}
.cards_top_height {
  
}
.search_input_field{
  background-color: transparent !important;
  border-color: var(--giveaways-text-color) !important;
  font-size: 0.9em !important;
  color: var(--giveaways-text-color) !important;
  padding: 9px 8px !important;
}
.search_input_field::placeholder{
  color: var(--giveaways-text-color) !important;
  font-size: 0.9em !important;
  font-weight: 500;
}
.home_cards_top_space {
  padding-top: 40px;
}
.more_home_card_btn {
  background-color: var(--primary-blue-color);
  display: flex;
  font-family: var(--primary-poppins-font);
  padding: 17px 50px;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 30px;
  color: var(--white);
  font-size: 1em;
  position: relative;
  margin-top: 50px;
  margin-bottom: 80px;
}
.more_home_card_btn:hover {
  background: linear-gradient(to right, #0d7dd2 25%, #406aa8 75%);
}
.location_modal_space {
  padding-top: 107px;
  padding-bottom: 97px;
}
.location_img_size {
  width: 80px;
  margin-left: 30px;
}
.share_live_location {
  font-family: var(--primary-poppins-font);
  color: var(--offer-created-text-color);
  font-size: 1.5em;
  font-style: normal;
  font-weight: 600;
}
.shared_location_p {
  font-family: var(--primary-poppins-font);
  color: var(--offer-created-date);
  font-size: 0.95em;
  font-style: normal;
  font-weight: 400;
  padding-right: 90px;
}
.share_live_location_btn {
  background-color: var(--primary-blue-color);
  display: flex;
  font-family: var(--primary-poppins-font);
  padding: 15px 50px;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 30px;
  color: var(--white);
  font-size: 1em;
  position: relative;
  margin-top: 40px;
}
.share_live_location_btn:hover {
  background: linear-gradient(to right, #0d7dd2 25%, #406aa8 75%);
}
.claim_airdrop_space {
  padding-top: 45px;
}
.claimdrop_input {
  width: 100%;
  padding: 12px 20px;
  border-radius: 10px;
  background: #f4f4f4;
  border: none;
  font-size: 0.9em;
  color: var(--offer-created-date);
  font-family: var(--primary-poppins-font);
}
.claimdrop_input:active,
.claimdrop_input:focus {
  outline: none;
  box-shadow: none;
  border: none;
}
.claimdrop_textarea {
  width: 100%;
  padding: 12px 20px;
  border-radius: 10px;
  background: #f4f4f4;
  border: none;
  font-size: 0.9em;
  color: var(--offer-created-date);
  font-family: var(--primary-poppins-font);
}
.claimdrop_textarea:active,
.claimdrop_textarea:focus {
  outline: none;
  box-shadow: none;
  border: none;
}
.form-check-input {
  width: 22px !important;
  height: 22px !important;
  border: 7px solid var(--radio-btn-color) !important;
  border-radius: 50% !important;
}
.form-check-input:checked[type=checkbox]{
  background-image: none !important;
  background-color: white !important;
}
.form-check-input:checked {
  background-color: #0c71bc !important;
  border-color: #0c71bc !important;
}
.submit_airdrop_form_btn {
  background-color: var(--primary-blue-color);
  display: flex;
  font-family: var(--primary-poppins-font);
  padding: 14px 45px;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 30px;
  color: var(--white);
  font-size: 1em;
  position: relative;
}
.submit_airdrop_form_btn:hover {
  background: linear-gradient(to right, #0d7dd2 25%, #406aa8 75%);
}
.thanks_airdrop_img {
  width: 130px;
}
.thanks_modal_text {
  color: var(--offer-created-text-color);
  font-family: var(--primary-poppins-font);
  font-size: 2em;
  font-style: normal;
  font-weight: 600;
}
.request_submit_p {
  color: var(--offer-created-date);
  font-family: var(--primary-poppins-font);
  font-size: 1em;
  font-style: normal;
  font-weight: 400;
}
.airdrops_home_card_label_text{
  color: var(--offer-created-date);
  font-family: var(--primary-poppins-font);
  font-size: 0.9em;
  font-style: normal;
  font-weight: 400;
}
.claim_sumit_airdrop_space {
  padding-top: 40px;
  padding-bottom: 40px;
}
.form-check-input:focus {
  box-shadow: none !important;
  outline: none !important;
}

/* Reponsive Queries  */
@media only screen and (max-width: 526px) and (min-width: 320px) {
  .home_cards_top_space {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .airdrop_home_card {
    box-shadow: 0px 16px 50px 0px rgba(0, 0, 0, 0.1);
    border: none !important;
    border-radius: 18px !important;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
  }
  .claim_now_btn {
    background-color: var(--primary-blue-color);
    display: flex;
    font-family: var(--primary-poppins-font);
    width: 50%;
    padding-top: 15px;
    padding-bottom: 15px;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 30px;
    color: var(--white);
    font-size: 1em;
    position: relative;
  }
  .claim_now_btn_disabled {
    background-color: var(--primary-blue-color);
    display: flex;
    font-family: var(--primary-poppins-font);
    width: 50%;
    padding-top: 15px;
    padding-bottom: 15px;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 30px;
    color: var(--white);
    font-size: 1em;
    position: relative;
    opacity: 0.8;
  }
  .claim_airdrop_space {
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .more_home_card_btn {
    background-color: var(--primary-blue-color);
    display: flex;
    font-family: var(--primary-poppins-font);
    padding: 17px 45px;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 30px;
    color: var(--white);
    font-size: 1em;
    position: relative;
    margin-top: 50px;
    margin-bottom: 50px;
  }
}
.form-group-checkbox {
  display: block;
  margin-bottom: 15px;
}

.form-group-checkbox input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.form-group-checkbox label {
  position: relative;
  cursor: pointer;
}

.form-group-checkbox label:before {
  content:'';
  -webkit-appearance: none;
  background-color: transparent;
  border: 2px solid var(--radio-btn-color);
  /* box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05); */
  padding: 10px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
  border-radius: 3px;
}
.form-group-checkbox input:checked + label:before {
  border-color: var(--primary-blue-color);
}
.form-group-checkbox input:checked + label:after {
  content: '';
  display: block;
  position: absolute;
  top: 5px;
  left: 9px;
  width: 6px;
  height: 14px;
  border: solid var(--primary-blue-color);
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.img_upload_airdrop_btn{
  background-color: var(--white);
  display: flex;
  font-family: var(--primary-poppins-font);
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 30px;
  color: var(--offer-created-date);
  font-size: 1em;
  position: relative;
  margin-bottom: 15px;
}
.view_dropdown_select{
  background-color: #f4f4f4;
  width: 100%;
  padding: 12px 10px;
  border-radius: 8px;
  border: none;
  color: var(--offer-created-date);
  font-family: var(--primary-poppins-font);
  font-size: 0.9em;
  font-style: normal;
  font-weight: 400;
}
.view_dropdown_select:focus,
.view_dropdown_select:active{
  outline: none;
}

.load-icon {
	width: 100px;
	height: 20px;
}
.load-icon span {
	position: absolute;
	display: inline-block;
	background: var(--white);
	width: 10px;
	height: 10px;
	border-radius: 100px;
  left: 50%;
  top: 50%;
  transform: translateY(-50%);
}
.load-icon span:nth-of-type(1) {
	left: 42%;
}
.load-icon span:nth-of-type(2) {
	left: 50%;
}
.load-icon span:nth-of-type(3) {
	left: 58%;
}

/* Animation */
.load-icon span {
	animation-name: loading;
	animation-duration: 1.5s;
	animation-iteration-count: infinite;
	animation-fill-mode: both;
}
.load-icon span:nth-of-type(2) {
	animation-delay: .2s;
}
.load-icon span:nth-of-type(3) {
	animation-delay: .4s;
}

/* Keyframes */
@keyframes loading {
	0% { opacity: 1; }
	50% { opacity: 0; }
	100% { opacity: 1; }
}
.select_img_input{
  width: 100%;
  background-color: #f4f4f4;
  border: none;
  padding: 12px 20px;
  color: var(--offer-created-date);
  text-align: left;
  border-radius: 8px;
  font-size: 0.9em;
  font-weight: 400;
  font-family: var(--primary-poppins-font);
}
.share_heading_icon{
  cursor: pointer;
}
.sign_up_form_title{
  color: var(--offer-created-date);
  font-family: var(--primary-poppins-font);
  font-size: 1.4em;
  font-style: normal;
  font-weight: 700;
}
.submit_signup_form_btn{
  color: white;
  font-family: var(--primary-poppins-font);
  font-size: 0.9em;
  font-style: normal;
  font-weight: 400;
  background-color: #0c71bc;
  border-radius: 5px;
  border: none;
  padding: 8px 12px;
}
.signup_error{
  font-size: 0.8em;
  color: red;
  font-family: var(--primary-poppins-font);
}
.dashboard_btn{
  background-color: #0d7dd2;
  color: white;
  border: 4px;
  padding: 6px 10px;
  border-radius: 5px;
  font-family: var(--primary-poppins-font);
  font-family: 0.95em;
}
.users_dashboard_heading{
  font-size: 1.3em;
  font-weight: 700;
  color: black;
  font-family: var(--primary-poppins-font);
}
.margin_bottom_datatable{
  margin-bottom: 600px;
}
.user_dashboard_page_space{
  padding-top: 60vh;
}