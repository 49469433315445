.airdrop_nav_btn {
  color: var(--btn-link-text);
  font-family: var(--primary-poppins-font);
  font-size: 1em;
  font-style: normal;
  font-weight: 400;
  margin-right: 30px;
  background: none;
  border: none;
}
.airdrop_nav_btn:hover {
  border-bottom: 2px solid var(--primary-blue-color);
}
.apply_for_pool_btn {
  background-color: var(--primary-blue-color);
  display: flex;
  font-family: var(--primary-poppins-font);
  padding: 13px 20px;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 30px;
  color: var(--white);
  font-size: 1em;
  position: relative;
  text-decoration: none;
}
.apply_for_pool_btn:hover {
  background: linear-gradient(to right, #0d7dd2 25%, #406aa8 75%);
}
.airdrop_giveaways {
  color: var(--giveaways-text-color);
  font-family: var(--primary-poppins-font);
  font-size: 3em;
  font-style: normal;
  font-weight: 900;
  padding-top: 60px;
}
.airdrop_home_bg {
  background-color: var(--home-bg);
  background-repeat: no-repeat;
  min-height: 450px;
  border-bottom-left-radius: 60px;
  border-bottom-right-radius: 60px;
}
.nav_border_bottom {
  border-bottom: 2px solid var(--nav-border-bottom);
}
.eye_outlined_icon {
  cursor: pointer;
  color: var(--black);
}
.untitled_airdrop_text_div {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  top: 25px;
}
.untitled_airdrop_text {
  color: var(--black);
  font-family: var(--primary-poppins-font);
  font-size: 1.3em;
  font-style: normal;
  font-weight: 600;
}
.search_btn{
  background-color: transparent !important;
  cursor: pointer;
  border-color: var(--giveaways-text-color) !important;
}
.search_icon{
  color: var(--giveaways-text-color) !important;
}
.search_input_field{
  background-color: transparent !important;
  border-color: var(--giveaways-text-color) !important;
  font-size: 0.9em !important;
  color: var(--giveaways-text-color) !important;
  padding: 9px 8px !important;
}
.search_input_field::placeholder{
  color: var(--giveaways-text-color) !important;
}
.search_input_field:focus,
.search_input_field:active{
  outline: none !important;
  box-shadow: none !important;
}
.approve_reject_airdrop{
  color: var(--giveaways-text-color);
  font-family: var(--primary-poppins-font);
  font-size: 0.95em;
  font-style: normal;
  font-weight: 500;
}
.modal_bg_color{

}
.approve_btn{
  background-color: var(--primary-blue-color);
  color: var(--white);
  font-size: 0.9em;
  padding: 10px 10px;
  width: 100%;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  font-family: var(--primary-poppins-font);
}
.reject_btn{
  background-color: var(--rejected-text-color);
  color: var(--white);
  font-size: 0.9em;
  padding: 10px 10px;
  width: 100%;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  font-family: var(--primary-poppins-font);
}
.cross_cursor{
  cursor: pointer;
  color: var(--giveaways-text-color);
}
/* Responsive Queries  */
@media only screen and (max-width: 991px) and (min-width: 320px) {
  .airdrop_nav_btn {
    margin-right: 0px;
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 576px) and (min-width: 320px) {
  .airdrop_giveaways {
    color: var(--giveaways-text-color);
    font-family: var(--primary-poppins-font);
    font-size: 3em;
    font-style: normal;
    font-weight: 900;
    padding-top: 70px;
  }
  .airdrop_home_bg {
    background-color: var(--home-bg);
    background-repeat: no-repeat;
    min-height: 420px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }
}
.nav_bg{
  background-color: #081015 !important;
  padding-top: 18px !important;
  padding-bottom: 18px !important;
}
.nav_bg_white{
  background-color: white !important;
  padding-top: 18px !important;
  padding-bottom: 18px !important;
}
.apply_pool_btn{
  background-color: #0d7dd2;
  font-size: 0.85em;
  padding: 7px 13px;
  border: none;
  border-radius: 5px;
  color: white;
  font-family: var(--primary-poppins-font);
}
.nav-item .nav-link{
  color: var(--white) !important;
  font-size: 0.9em;
  font-family: var(--primary-poppins-font);
}
.nav-item .nav-link:hover{
  background-color: #18151C;
  border-radius: 5px;
  color: var(--white);
  font-size: 0.9em;
  font-family: var(--primary-poppins-font);
}
.dropdown-toggle::after{
  display: none !important;
}
.dropdown-menu{
  min-width: 347px !important;
  left: -24px !important;
}
.dropdown-item:hover, .dropdown-item:focus{
  background-color: transparent !important;
  color: var(--black) !important;
}
.highLightLaunch {
  height: 22px;
  width: 75px;
  background: #DA5077;
  border-radius: 0 5px;
  margin: 0 auto;
  text-align: center;
  position: absolute;
  bottom: 0;
  left: 108px;
  top: -15px;
  transform: translate(-50%, 0%);
  animation: blinker 1.2s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.highLightText {
  font-size: 10px;
  color: white;
}
.highLightLaunchNew {
  height: 22px;
  width: 40px;
  background: #DA5077;
  border-radius: 0 5px;
  margin: 0 auto;
  text-align: center;
  position: absolute;
  bottom: 0;
  left: 32px;
  top: -15px;
  transform: translate(-50%, 0%);
  z-index: 1000;
}

.highLightTextNew {
  font-size: 10px;
  color: white;
}