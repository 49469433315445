.airdrop_form_bg {
  background-color: var(--radio-btn-color);
}
.form_tabs_spacing {
  padding-top: 35px;
  padding-bottom: 50px;
}
.form_pills_bg {
  background-color: var(--white);
  border-radius: 15px;
  min-width: 95px;
}
.form_buttons_bg .nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: transparent !important;
}
.navigation_image_space {
  margin-top: 45px;
}
.airdrop_tabs_h {
  font-family: var(--primary-poppins-font);
  font-size: 1.5em;
  font-style: normal;
  font-weight: 600;
  color: var(--black);
}
.airdrop_tab_add_description {
  font-family: var(--primary-poppins-font);
  font-size: 1em;
  font-style: normal;
  font-weight: 400;
  color: var(--offer-created-date);
}
.airdrops_tabs_bg {
  border-radius: 10px;
  background-color: var(--white);
  padding-top: 40px;
  padding-bottom: 35px;
  padding-left: 40px;
  padding-right: 40px;
}
.airdrop_tabs_untitled_question {
  color: var(--black);
  font-family: var(--primary-poppins-font);
  font-size: 1.3em;
  font-style: normal;
  font-weight: 500;
}
.option_label_text {
  color: var(--offer-created-date);
  font-family: var(--primary-poppins-font);
  font-size: 0.95em;
  font-style: normal;
  font-weight: 400;
  cursor: pointer;
}
.form-check-input {
  width: 20px !important;
  height: 20px !important;
  border: 7px solid var(--radio-btn-color) !important;
  border-radius: 50%;
}
.form-check-input:checked[type=checkbox]{
  background-image: none !important;
  background-color: white !important;
}

.form-check-input:checked {
  background-color: #0c71bc !important;
  border-color: #0c71bc !important;
}
.form-check-input:focus {
  box-shadow: none !important;
  outline: none !important;
}
.border-bottom-form-hr {
  height: 1px;
  width: 100%;
  background-color: var(--radio-btn-color);
}
.icon_cursor {
  cursor: pointer;
}
.line_vr_bar {
  width: 1px;
  height: 23px;
  background-color: var(--radio-btn-color);
}
.top_bar_effect {
  width: 25px;
  height: 10px;
  background-color: var(--top-bar-effect);
  border-radius: 5px;
}
.insert_image_h {
  color: var(--black);
  font-family: var(--primary-poppins-font);
  font-size: 1.4em;
  font-style: normal;
  font-weight: 600;
}
.image_upload_modal_btn {
  font-family: var(--primary-poppins-font);
  font-size: 0.9em;
  font-style: normal;
  font-weight: 400;
  background: none;
  border: none;
  padding-bottom: 8px;
}
.image_upload_modal_btn:hover {
  border-bottom: 2px solid var(--primary-blue-color);
}
.image_upload_modal_btn_active {
  border-bottom: 2px solid var(--primary-blue-color);
}
.image_upload_space {
  padding-top: 60px;
  padding-bottom: 65px;
}
.browse_modal_btn {
  background-color: var(--primary-blue-color);
  display: flex;
  font-family: var(--primary-poppins-font);
  padding: 15px 40px;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 30px;
  color: var(--white);
  font-size: 0.9em;
  position: relative;
  text-decoration: none;
}
.browse_modal_btn:hover {
  background: linear-gradient(to right, #0d7dd2 25%, #406aa8 75%);
}
.drag_a_file_p {
  color: var(--offer-created-date);
  font-family: var(--primary-poppins-font);
  font-size: 1em;
  font-style: normal;
  font-weight: 400;
}
.selected_image_preview_bg {
  background-color: var(--white);
  padding-left: 35px;
  padding-top: 40px;
  padding-bottom: 40px;
  padding-right: 35px;
  border-radius: 18px;
}
.image_title_h {
  color: var(--black);
  font-family: var(--primary-poppins-font);
  font-size: 1.4em;
  font-style: normal;
  font-weight: 600;
}
.custom-select-form {
  position: relative;
  top: 7px;
}
.custom-select-wrapper-form {
  position: relative;
  top: -8px;
}

.custom-select-header-form {
  padding: 10px;
  cursor: pointer;
  border-radius: 50px;
  padding: 10px 20px;
  width: fit-content;
  background: var(--white);
  color: var(--offer-created-date);
  font-family: var(--primary-poppins-font);
  font-size: 0.9em;
  font-style: normal;
  border: 1px solid var(--offer-created-date);
  border-radius: 8px;
}

.custom-select-options-form {
  list-style: none;
  padding: 0;
  margin: 0;
  border-top: none;
  position: absolute;
  top: 100%;
  left: -17px;
  display: none;
  background-color: var(--white);
  border-radius: 20px;
  background: var(--white);
  padding-top: 30px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 10px;
  box-shadow: 0px 16px 50px 0px rgba(0, 0, 0, 0.1);
  width: 255px;
  z-index: 1000;
}

.custom-select-wrapper-form.open .custom-select-options-form {
  display: block;
  top: -150px;
}
.li_cursor {
  cursor: pointer;
}
.text_blue_color {
  color: var(--primary-blue-color);
}
.form_input_field_question {
  border: none;
  border-bottom: 1px solid var(--radio-btn-color);
  color: var(--black);
  font-family: var(--primary-poppins-font);
  font-size: 1.2em;
  font-style: normal;
  font-weight: 400;
  width: 100%;
  padding: 5px 0px;
}
.form_input_field_question::placeholder {
  color: var(--black);
  font-family: var(--primary-poppins-font);
  font-size: 1.2em;
  font-style: normal;
  font-weight: 400;
}
.form_input_field_question:focus,
.form_input_field_question:active {
  outline: none;
}
.form_input_field {
  border: none;
  border-bottom: 1px solid var(--radio-btn-color);
  color: var(--offer-created-date);
  font-family: var(--primary-poppins-font);
  font-size: 1em;
  font-style: normal;
  font-weight: 400;
  width: 100%;
  padding: 10px 0px;
}
.form_input_field::placeholder {
  color: var(--offer-created-date);
  font-family: var(--primary-poppins-font);
  font-size: 1em;
  font-style: normal;
  font-weight: 400;
}
.form_input_field:focus,
.form_input_field:active {
  outline: none;
}

.required-label {
  position: relative;

  &:before,
  &:after {
    bottom: 0;
    content: '';
    display: inline-block;
    margin: auto;
  }

  &:before {
    background-color: var(--radio-btn-color);
    border-radius: 50px;
    top: 0;
    height: 19px;
    width: 38px;
  }

  &:after {
    background-color: var(--offer-created-date);
    border-radius: 50%;
    position: absolute;
    top: -6px;
    height: 18px;
    left: 1px;
    transition: 0.4s;
    width: 18px;
  }
}

.toggle-checkbox {
  display: none;

  &:checked ~ .required-label:before {
    background-color: var(--radio-btn-color);
  }

  &:checked ~ .required-label:after {
    background-color: var(--primary-blue-color);
    transform: translateX(19px);
  }
}
.required_text {
  color: var(--offer-created-date);
  font-family: var(--primary-poppins-font);
  font-size: 1em;
  font-style: normal;
  font-weight: 400;
  position: relative;
  top: -4px;
}
.option_dropdown_text{
  color: var(--offer-created-date);
font-family: var(--primary-poppins-font);
font-size: 0.95em;
font-style: normal;
font-weight: 400;
}

.form-group {
  display: block;
  margin-bottom: 15px;
}

.form-group input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.form-group label {
  position: relative;
  cursor: pointer;
}

.form-group label:before {
  content:'';
  -webkit-appearance: none;
  background-color: transparent;
  border: 1px solid var(--radio-btn-color);
  padding: 10px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
  border-radius: 3px;
}
.form-group input:checked + label:before {
  border-color: var(--primary-blue-color); /* Change border color to blue */
}
.form-group input:checked + label:after {
  content: '';
  display: block;
  position: absolute;
  top: 7px;
  left: 9px;
  width: 5px;
  height: 11px;
  border: solid var(--primary-blue-color);
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.allow_file_type_p{
  color: var(--offer-created-date);
font-family: var(--primary-poppins-font);
font-size: 1em;
font-style: normal;
font-weight: 400;
}
.select_files_type{
  color: var(--offer-created-date);
  font-family: var(--primary-poppins-font);
  font-size: 0.95em;
  font-style: normal;
  font-weight: 400;
  border: 1px solid var(--offer-created-date);
  border-radius: 4px;
  padding: 4px 11px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url('../../assets/images/arrowdown.png') no-repeat;
  background-position: center;
  background-position-x: 30px;
  background-size: 11px;
  padding-right: 24px !important;
}
.select_files_type:focus{
  outline: none;
}
.maximum_file_size{
  color: var(--offer-created-date);
  font-family: var(--primary-poppins-font);
  font-size: 0.95em;
  font-style: normal;
  font-weight: 400;
  border: 1px solid var(--offer-created-date);
  border-radius: 4px;
  padding: 4px 11px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url('../../assets/images/arrowdown.png') no-repeat;
  background-position: center;
  background-position-x: 54px;
  background-size: 11px;
  padding-right: 24px !important;
}
.maximum_file_size:focus{
  outline: none;
}
.form_accept_p{
  color: var(--offer-created-date);
font-family: var(--primary-poppins-font);
font-size: 0.9em;
font-style: normal;
font-weight: 400;
}
.view_folder_p{
  color: var(--primary-blue-color);
font-family: var(--primary-poppins-font);
font-size: 1em;
font-style: normal;
font-weight: 400;
}
.choice_grid_p{
  color: var(--offer-created-text-color);
font-family: var(--primary-poppins-font);
font-size: 1.05em;
font-style: normal;
font-weight: 500;
}
.options_input_field{
  width: 100%;
  color: var(--offer-created-text-color);
  font-family: var(--primary-poppins-font);
  font-size: 0.9em;
  font-style: normal;
  border: none;
  border-bottom: 1px solid var(--radio-btn-color);
  padding-top: 8px;
  padding-bottom: 8px;
}
.options_input_field:focus,
.options_input_field:active{
  outline: none;
}
.options_label_circle{
  width: 20px;
height: 20px;
border: 3px solid var(--radio-btn-color);
border-radius: 50%;
}
.checkboxes_label_circle{
  width: 20px;
height: 20px;
border: 2px solid var(--radio-btn-color);
border-radius: 5px;
}
.cross_icon_cursor{
  cursor: pointer;
}
.dropdown_count_span{
  color: var(--offer-created-text-color);
  font-family: var(--primary-poppins-font);
  font-size: 0.9em;
  font-style: normal;
}
.form_title_input_field{
  width: 100%;
  color: var(--black);
  font-weight: 600;
  font-family: var(--primary-poppins-font);
  font-size: 2em;
  font-style: normal;
  border: none;
}
.form_title_input_field::placeholder{
  color: var(--black);
}
.form_title_input_field:focus,
.form_title_input_field:active{
  outline: none;
  border: none;
}
.expiry_date_input{
  background-color: #f5f6f7;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  font-family: var(--primary-poppins-font);
  color: var(--offer-created-date);
}
.expiry_date_input:focus,
.expiry_date_input:active{
  outline: none;
}
.nav_link_navigate{
  background-color: transparent !important;
}
