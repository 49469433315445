.submit_airdrop_form_btn {
  background-color: var(--primary-blue-color);
  display: flex;
  font-family: var(--primary-poppins-font);
  padding: 14px 45px;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 30px;
  color: var(--white);
  font-size: 1em;
  width: auto;
  height: auto;
  animation: zoom-in-zoom-out 1s ease-out infinite;
  /* position: relative; */
}
.submit_airdrop_form_btn:hover {
  background: linear-gradient(to right, #0d7dd2 25%, #406aa8 75%);
}
@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.1, 1.1);
  }
  100% {
    transform: scale(1, 1);
  }
}
.sign_in_btn{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.wrapper{
  width: 370px;
    border:2px solid rgba(255, 255, 255, .2);
    backdrop-filter:blur(20px);
    box-shadow: 0 0 10px rgba(0 , 0 , 0 , .1);
    color: #fff;
    border-radius: 8px;
    padding: 80px 40px;
}