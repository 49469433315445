.data_bg_color {
  background-color: var(--radio-btn-color);
  padding-top: 40px;
  padding-bottom: 50px;
}
.airdrop_table_shadow {
  border-radius: 20px;
  background: var(--white);
  box-shadow: 0px 16px 50px 0px rgba(0, 0, 0, 0.1);
  padding: 40px;
}
.airdrops_data_h5 {
  color: var(--black);
  font-family: var(--primary-poppins-font);
  font-size: 1.6em;
  font-style: normal;
  font-weight: 600;
}
.airdrop_table_h {
  font-family: var(--primary-poppins-font);
  font-size: 0.9em;
  font-style: normal;
  font-weight: 600;
  color: var(--offer-created-date);
}
.columns_top_bottom_space {
  padding-bottom: 20px !important;
}
.columns_top_space_th {
  vertical-align: middle;
}
thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: var(--radio-btn-color) !important;
}
.airdrop_table_p {
  font-family: var(--primary-poppins-font);
  color: var(--offer-created-date);
  font-size: 0.9em;
  font-style: normal;
  font-weight: 400;
}

.custom-select-wrapper {
  position: relative;
  top: 7px;
  min-width: 140px;
}

.custom-select-header {
  padding: 10px;
  cursor: pointer;
  border-radius: 50px;
  padding: 10px 25px;
  width: fit-content;
  background: #e4f2db;
  color: var(--options-green-color);
  font-family: var(--primary-poppins-font);
  font-size: 0.9em;
  font-style: normal;
}

.custom-select-options {
  list-style: none;
  padding: 0;
  margin: 0;
  border-top: none;
  position: absolute;
  top: 100%;
  left: -40px;
  width: 100%;
  display: none;
  background-color: var(--white);
  border-radius: 20px;
  background: var(--white);
  padding: 30px;
  box-shadow: 0px 16px 50px 0px rgba(0, 0, 0, 0.1);
  width: 185px;
  z-index: 1000;
}

.approved {
  background-color: var(--options-green-color);
  padding: 10px 15px;
  cursor: pointer;
  color: var(--green-color);
  border-radius: 50px;
  margin-bottom: 12px;
  font-size: 0.9em;
}
.pending {
  background-color: var(--pending-color);
  padding: 10px 15px;
  cursor: pointer;
  color: var(--pending-text-color);
  border-radius: 50px;
  margin-bottom: 12px;
  font-size: 0.9em;
}
.rejected {
  background-color: var(--rejected-color);
  padding: 10px 15px;
  cursor: pointer;
  color: var(--rejected-text-color);
  border-radius: 50px;
  margin-bottom: 12px;
  font-size: 0.9em;
}

.custom-select-wrapper.open .custom-select-options {
  display: block;
  margin-top: 5px;
}
.flex-container {
  display: flex;
}
.apply_pool_btn_csv {
  text-decoration: none;
  background-color: #0d7dd2 !important;
  font-size: 0.85em;
  padding: 9px 13px !important;
  border: none;
  border-radius: 5px;
  color: white;
  font-family: var(--primary-poppins-font);
}
.tabs_buttons {
  background-color: #0d7dd2 !important;
  color: white !important;
  min-height: 38px !important;
  border-radius: 8px !important;
  font-size: 0.9em !important;
  text-transform: capitalize !important;
  min-width: 87px !important;
}
.css-19kzrtu {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.sorting {
  font-family: var(--primary-poppins-font) !important;
  font-size: 0.9em !important;
  font-style: normal !important;
  font-weight: 600 !important;
  color: var(--offer-created-date) !important;
}

table.dataTable thead > tr > th.sorting:before,
table.dataTable thead > tr > th.sorting:after {
  display: none !important;
  content: '' !important;
}
.data_table_td {
  font-family: var(--primary-poppins-font) !important;
  color: var(--offer-created-date) !important;
  font-size: 0.9em !important;
  font-style: normal !important;
  font-weight: 400 !important;
}
table.dataTable.table-striped > tbody > tr:nth-of-type(2n + 1) > * {
  box-shadow: none !important;
}
.page-link.active,
.active > .page-link {
  background-color: #0d7dd2 !important;
  color: white !important;
  box-shadow: none !important;
}
.page-link {
  box-shadow: none !important;
  font-family: var(--primary-poppins-font) !important;
  color: var(--offer-created-date) !important;
  font-size: 0.9em !important;
  font-style: normal !important;
  font-weight: 400 !important;
}
div.dataTables_wrapper div.dataTables_info {
  font-family: var(--primary-poppins-font) !important;
  color: var(--offer-created-date) !important;
  font-size: 0.9em !important;
  font-style: normal !important;
  font-weight: 400 !important;
}
div.dataTables_wrapper div.dataTables_length label{
  font-family: var(--primary-poppins-font) !important;
  color: var(--offer-created-date) !important;
  font-size: 0.9em !important;
  font-style: normal !important;
  font-weight: 400 !important;
}
div.dataTables_wrapper div.dataTables_filter label{
  font-family: var(--primary-poppins-font) !important;
  color: var(--offer-created-date) !important;
  font-size: 0.9em !important;
  font-style: normal !important;
  font-weight: 400 !important;
}
.form-control:focus{
  box-shadow: none !important;
  border-color: #dee2e6 !important;
}
.airdrop_table_edit_btn{
  background-color: #0d7dd2;
  border: none;
  color: white;
  padding-left: 9px;
  padding-right: 9px;
  padding-top: 4px;
  padding-bottom: 4px;
  font-family: var(--primary-poppins-font);
  border-radius: 5px;
  font-size: 0.95em;
  text-decoration: none;
}
.table_export_button{
  background-color: #0d7dd2;
  border: none;
  color: white;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 6px;
  padding-bottom: 6px;
  font-family: var(--primary-poppins-font);
  border-radius: 5px;
  font-size: 0.95em;
  text-decoration: none;
}
.are_you_sure{
  font-family: var(--primary-poppins-font);
  font-size: 1.3em;
  text-decoration: none;
}
.confirmation_p{
  font-family: var(--primary-poppins-font);
  font-size: 0.95em;
  text-decoration: none;
}
.cross_btn{
  border: 4px solid #DC3545;
  color: #DC3545;
  background: none;
  border-radius: 50%;
  padding: 4px 17px;
  font-size: 30px;
}
@media only screen and (max-width: 6000px) and (min-width: 320px) {
  .table_overflow_x_scroll {
    min-width: auto;
    overflow-x: auto !important;
  }
}
@media only screen and (max-width: 1200px) and (min-width: 320px) {
  .table_overflow_x_status {
    min-width: auto;
    overflow-x: auto !important;
  }
}

@media only screen and (max-width: 6000px) and (min-width: 992px) {
  .spacing_bottom{
    margin-bottom: 60vh;
}
}
.claimdrop_top_input {
  margin-top: 10px;
  padding: 10px 15px;
  border-radius: 10px;
  background: #f4f4f4;
  border: none;
  font-size: 0.9em;
  color: var(--offer-created-date);
  font-family: var(--primary-poppins-font);
}
.claimdrop_top_input:active,
.claimdrop_top_input:focus {
  outline: none;
  box-shadow: none;
  border: none;
}
.table_overflow_x_scroll::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
  height: 6px;
  border-radius: 8px;
}

/* Track */
.table_overflow_x_scroll::-webkit-scrollbar-track {
  background: lightgray; /* Color of the track */
}

/* Handle */
.table_overflow_x_scroll::-webkit-scrollbar-thumb {
  background: #2c9cf2; /* Color of the scrollbar handle */
  height: 6px;
}

/* Handle on hover */
.table_overflow_x_scroll::-webkit-scrollbar-thumb:hover {
  background: #2c9cf2; /* Color of the scrollbar handle on hover */
}