:root {
  --primary-blue-color: #0c71bc;
  --giveaways-text-color: #445967;
  --btn-link-text: #88949b;
  --home-bg: #081015;
  --white: #ffffff;
  --black: #000000;
  --primary-text: #fcfcfc;
  --footer-link-text: rgba(255, 255, 255, 0.5);
  --offer-created-text-color: #262829;
  --offer-created-date: #5f6264;
  --airdrop-card-remaining-color: #2ca43f;
  --radio-btn-color: #e6e7e8;
  --nav-border-bottom: rgba(12, 113, 188, 0.3);
  --options-green-color: #e4f2db;
  --pending-color: #dbe7f2;
  --rejected-color: #f2dbdb;
  --green-color: #3a8246;
  --rejected-text-color: #823a3a;
  --pending-text-color: #3a5782;
  --top-bar-effect: #D9D9D9;
  /* Fonts  */
  --primary-poppins-font: "Rubik";
}
